import { Dispatch, SetStateAction, useState, useEffect } from 'react'
import { ITask } from '../../Interfaces/Task'
import Card from '../Card/Card'
import Task from '../Task/Task'
import TaskExpandable from '../Task/TaskExpandable'
//import callLink from './callLink'  //C:\Users\horstkandutsch\Source\eCockpit\eCockpit NEU\src\Hooks\Utilities\callLink.tsx
import { AddRounded, CheckBoxOutlineBlankRounded, CheckBoxRounded, CheckRounded, RefreshRounded, MoreVertRounded, Call, CallEnd, CallMissed, Email, PhoneMissed } from '@mui/icons-material'
import { Button, Chip, Tooltip } from '@mui/material'
import { TextField } from '@mui/material'
import styles from './TaskCard.module.css'
import ErrorDialog from '../Dialogs/ErrorDialog/ErrorDialog'
import { useDeliveryNote } from '../../Contexts/DeliveryNoteContext/DeliveryNoteContext'
import IconTooltipButton from '../Buttons/IconTooltipButton/IconTooltipButton'
import { card } from '../../Interfaces/Card'
import CreateTaskDialog from '../Dialogs/CreateTaskDialog/CreateTaskDialog'
import { blankcard } from '../ServiceCard/ServiceCard'
import { useModals } from '../../Contexts/DialogContext/DialogProvider'
import { useLocation, useNavigate } from 'react-router-dom'
import ContextMenu from '../Menu/ContextMenu'
import { Grow } from '@mui/material'
import { string } from 'zod'
import { ContactDataType, IContactData, IPerson, IPersonDTO, useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
//mport { companyDataContext} from '../../Contexts/ContactsContext/ContactsProvider'
import { useSnackBar } from '../../Hooks/useSnackBar'
import axios, { AxiosResponse } from 'axios'
import JournalEntry from '../Journal/JournalEntry'
import { journalDTO } from '../../Interfaces/Journal'
import InputField from '../InputField/InputField'
import JournalCard from '../JournalCard/JournalCard'
import { useJournal } from '../../Contexts/JournalContext/JournalProvider'
import { VariableDeclaration } from 'typescript'
import { IOrganization, IOrganizationDTO } from '../../Interfaces/Company'
import { useUser } from '../../Contexts/UserContext/UserContext'
import MissedCallMailDialog from './MissedCallMailDialog'
import LiveTicker from '../LiveTicker/LiveTicker'
import useAuthConnection from '../../Hooks/useAuthConnection'


export type ITaskSelectMode = "select" | "normal"

{/**************************************************************************************/ }
//Daten lesen! 
//Journaleintrag muss erstellt werden

export type PhoneCardModes = "person" | "organization" | "undefined"



interface PhoneCard extends blankcard{
  clickedPerson?: IPerson
  clickedCompany?: IOrganization
  phoneCardMode?: PhoneCardModes
  calledPerson: IPersonDTO, setCalledPerson?: Dispatch<SetStateAction<IPersonDTO>>,
  phoneJournalTitle: string, setPhoneJournalTitle?: Dispatch<SetStateAction<string>>,
  phoneJournalEntry: string, setPhoneJournalEntry?: Dispatch<SetStateAction<string>>,
  phoneJournalTime: Date, setPhoneJournalTime?: Dispatch<SetStateAction<Date>>
  phoneJournalDuration: number, setPhoneJournalDuration?: Dispatch<SetStateAction<number>>
}

{/**************************************************************************************/ }



const getTimeNumber = (phoneJournalTime: Date) => {

  var anrufEnde = new Date();
  var numberOfMlSecondsEnde = anrufEnde.getTime();
  var numberOfMlSecondsStart = phoneJournalTime.getTime();
  var durationRaw = (numberOfMlSecondsEnde - numberOfMlSecondsStart) / 60000;
  var duration = Math.round(durationRaw * 100) / 100;


  return duration;
}

//Hier wird der Anruf ausgelöst -> altes Return: <a href={url}>Link to resource</a>   neues return: (window.open(url));
const raiseCallViaURL = async (link: string, userIP: string) => {
  try {
    const baseUrl = 'http://';


    const pathParts = [userIP, '//command.htm?number=', link, '&outgoing_uri=URI'];//hier zwei Function-Calls; getMyPhoneIP & getNumberFromInput
    const url = buildUrl(baseUrl, pathParts);

    return (axios.get(url));
  }
  catch (error) {
    console.log(' ' + error);
  }
};



//Hier wird der Anruf via API ausgelöst
const RaiseCallViaAPI = async (link: string, userIP: string, connection:any) => {
  //console.log("######## ", link, " ######### ", userIP)

  
  //const connection = useAuthConnection();
  
      //Objekt zur Übergabe d. Parameter gestalten:
      const callParams = {
          "phoneNumber" : link,
          "userIP" : userIP
      }

      //console.log("######## ", link, " ######### ", userIP)
  
  // Make API call to get CSV string
  
      connection.post("/Phone/Call", callParams) //damit ruft UI die API auf...
          .then((res: AxiosResponse) => {
              // Der Anruf wird ausgeführt, hier Snackbar + Journaleintrag setzen. Idee: Journaleintrag erst, wenn aufgelegt wurde? Kann das der Server melden?
              
          })
          .catch((error: any) => {
              console.error(`Error: ${error}`);
          })

};


//Hier wird aufgelegt: string url = "http://" + MIP + "/command.htm?RELEASE_ALL_CALLS";
const endCall = (userIP: string) => {

  const baseUrl = 'http://';
  const pathParts = [userIP, '/command.htm?RELEASE_ALL_CALLS'];//hier wird aufgelegt
  const url = buildUrl(baseUrl, pathParts);

  return (axios.get(url));
};

//Hier wird aufgelegt: string url = "http://" + MIP + "/command.htm?RELEASE_ALL_CALLS";
const endCallviaApi = (userIP: string, connection: any) => {

  const callParams = {
        "userIP" : userIP
}

//console.log("######## ", link, " ######### ", userIP)

// Make API call to get CSV string

connection.post("/Phone/stoppCall", callParams) //damit ruft UI die API auf...
    .then((res: AxiosResponse) => {
        // Der Anruf wird ausgeführt, hier Snackbar + Journaleintrag setzen. Idee: Journaleintrag erst, wenn aufgelegt wurde? Kann das der Server melden?
        
    })
    .catch((error: any) => {
        console.error(`Error: ${error}`);
    })

};


//URL-Builder zum Zusammenfügen der URL aus der Usereingabe, der IP-Adresse a.d. Datenbank & der Telefonnummer, die eingegeben wurde
//string url = "http://" + MIP + "//command.htm?number=" + telnr + "&outgoing_uri=URI";
const buildUrl = (baseUrl: string, parts: string[]) => {
  let url = baseUrl;

  for (let i = 0; i < parts.length; i++) {
    url += parts[i];
  }

  return url;
};



//Wir brauchen die User-IP a.d. Datenbank. Damit bauen wir uns die URL für den Auslöser des Phonecall, danach müssen wir einen Journaleintrag kreieren.
function PhoneCard({ height = "100%", width = "100%", order, cta, clickedPerson, clickedCompany, phoneCardMode, setCalledPerson, phoneJournalTitle, setPhoneJournalTitle, phoneJournalEntry, setPhoneJournalEntry,
  phoneJournalTime, setPhoneJournalTime, phoneJournalDuration, setPhoneJournalDuration }: PhoneCard) {
  const [modalOpen, setModalOpen] = useState(false);
  const connection = useAuthConnection();

  useEffect(() => {
    if (phoneCardMode === "person" && clickedPerson) {
      let numbers = clickedPerson.contactData?.filter((temp: IContactData) => temp.type === ContactDataType.phone);
      if (numbers && (numbers?.length ?? 0 > 0)) {
        setLink(numbers[0].value ?? "")
      }

      let mails = clickedPerson.contactData?.filter((temp: IContactData) => temp.type === ContactDataType.mail);
      if (mails && (mails?.length ?? 0 > 0)) {
        setMail(mails[0].value ?? 0)
      }
    }
    else if(phoneCardMode === "organization" && clickedCompany) {
      setLink(clickedCompany.telephone ?? "")
      setMail(clickedCompany.eMail ?? "")
    }
    else {
      setLink("");
      setMail("");
    }

  }, [phoneCardMode])

  const { user } = useUser();
  let userIP: string = "";

  if (user) {
    userIP = String(user!!.voipAddress);
  }
  else {
    userIP = "keine IP hinterlegt."
  }

  //const CreateJournalEntry = ({ object, setObject }: { object?: journalDTO | null, setObject: Dispatch<SetStateAction<journalDTO>> }) => {}

  // Erstelle einen state-Wert für den Link
  const [link, setLink] = useState('');
  const [mail, setMail] = useState('');
  const [name, setName] = useState('');
  const { enqueueSnackbar } = useSnackBar()


  // Erstelle eine Funktion, die den Link auslöst
  const handleAnrufenClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    if (setPhoneJournalTime) setPhoneJournalTime(new Date());
    //Hier muss ich den State auf die angerufene Person setzen:

    if (setCalledPerson && clickedPerson) setCalledPerson(clickedPerson)
    // { enqueueSnackbar("CalledPerson gesetzt: " + clickedPerson.completeName, { variant: "info" }) }

    if (setPhoneJournalTitle && (clickedPerson || clickedCompany)) setPhoneJournalTitle("Anruf der Firma/Person: " + clickedPerson?.completeName ?? (clickedCompany?.name ?? ""));

    //if (setPhoneJournalEntry) setPhoneJournalEntry("Start des Telefongesprächs: " + phoneJournalTime.toLocaleString() + "\n ");

    //if (setPhoneJournalDuration) setPhoneJournalDuration(getTimeNumber(phoneJournalTime));

    try {

      if (userIP !== undefined) {

        RaiseCallViaAPI(link, userIP, connection);
        //raiseCallViaURL(link, userIP);
      }

      { enqueueSnackbar("Telefonnummer wird gewählt. Von: " + user!!.userId + " " + user!!.username + " " + userIP, { variant: "info" }) }
    }
    catch (error) {
      { enqueueSnackbar("Fehler beim Wählen: " + error, { variant: "error" }) }
    }
  }


  const refreshPhoneCard = () => {

    setLink("");

  }

  // Erstelle eine Funktion fürs Auflegen
  const handleAuflegenClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    endCallviaApi(userIP, connection);
    { enqueueSnackbar("Anruf beendet.", { variant: "info" }) }
    if (setPhoneJournalDuration) setPhoneJournalDuration(getTimeNumber(phoneJournalTime));

    //if (setPhoneJournalEntry) setPhoneJournalEntry(phoneJournalEntry + " - mit einer Dauer von " + phoneJournalDuration + " Minuten \n\n Inhalt:\n ");

    //setPhoneJournalTitle("");

    refreshPhoneCard();

  }

  // Erstelle eine Funktion für "nicht erreichbar"
  const handleNichtErreichbarClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    endCallviaApi(userIP, connection);
    if (setCalledPerson && clickedPerson) setCalledPerson(clickedPerson)

    if (setPhoneJournalEntry) setPhoneJournalEntry("Der/die TeilnehmerIn war am " + phoneJournalTime.toLocaleString() + " nicht erreichbar.");
    //{ enqueueSnackbar("Journaleintrag - nicht erreichbar - erstellt", { variant: "info" }) }
  }


  // Erstelle eine Funktion für "nicht erreichbar + RückrufMail"
  const handleRR: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    if (setCalledPerson && clickedPerson) setCalledPerson(clickedPerson)

    //{ enqueueSnackbar("Nicht erreichbar + Email", { variant: "info" }) }
    if (setPhoneJournalEntry) setPhoneJournalEntry("Der/die TeilnehmerIn war am " + phoneJournalTime.toLocaleString() + " nicht erreichbar, ein Rückrufmail wurde erstellt.");
    //"mailto:example@example.com?subject=Your Subject&body=Your message
/*
    const loggedInUser = "Testuser";
    const loggedInUserPhoneNumber = "0463 1234";

    //ToDo: Namen, Daten und Rückrufnummer erstellen (bei Body) - besser: Textbausteine unter Einstellungen definieren und hier einsetzen!
    const subject = "Verpasster Anruf";
    const body = "Sehr geehrte/sehr geehrter " + (clickedPerson?.completeName ?? clickedCompany?.name) + "! \r\r" + "Sie haben soeben einen Anruf von " + loggedInUser + " verpasst. \n" +
      " Bitte um Rückruf unter der Telefonnummer: " + "LoggedInUserTelefonnummer \n\n" + "Vielen Dank, \n" + "i.A.v. " + loggedInUser +
      "\n (Dies ist eine automatisch generierte Verständigung via eCockpit, nähere Infos unter www.ecockpit.at)";
    const email = mail;
    window.location.href = "mailto:" + email + "?subject=" + subject + "&body=" + body + "";*/
    endCallviaApi(userIP, connection);
  }

  const dialogprovider = useModals();

  return (
    <Card
      title="Telefon"
      style={{ height, width, order }}
      cta={cta ?? true}
      ctaTitle='Telefonnummer hinzufügen'
      buttonIcon={<AddRounded fontSize='small' />}

      ctaFunction={() => { dialogprovider.changeVisibility!("editCreatePersonfromPhone", true, { props: { object: { telefonnummer: link }, mode: "create" } }) }}

    >
      <div className='flex column' style={{ gap: "2px" }}>
        {
          <form>
            <div>
              <span className="label">
                Organisation, bzw. Person: {phoneCardMode === "person" ? clickedPerson?.completeName : (phoneCardMode === "organization" ? clickedCompany?.name : "")}
              </span>
            </div>
            <div>
              <div>
                <InputField
                  type="tel"
                  //padding = "2px"
                  //align="center"
                  placeholder='Telefonnummer eingeben oder Kontakt anklicken'
                  value={link}
                  onChangeHandler={(val) => setLink(val)}
                />
              </div>
              {/* 
                <TextField id="outlined-basic" style={{ width: "75%" }} label="Telefonnummer" variant="filled" value={link} onChange={
                  (e) => setLink(e.target.value)
                }
                />*/}
              <div>
                <span> </span>
                <Tooltip title="Anrufen">
                  <Button onClick={handleAnrufenClick} color="success" variant='contained' style={{ width: "48%", padding: "2px", margin: "4px" }}><Call /></Button>
                </Tooltip>
                <Tooltip title="Auflegen">
                  <Button onClick={handleAuflegenClick} color="error" variant='contained' style={{ width: "48%", padding: "2px", margin: "4px" }}><CallEnd /></Button>
                </Tooltip>
              </div>
            </div>
            <div>
              <Tooltip title="Nicht erreichbar">
                <Button variant="contained" onClick={handleNichtErreichbarClick} color="inherit" style={{ width: "48%", padding: "2px", margin: "4px" }}><PhoneMissed></PhoneMissed></Button>
              </Tooltip>
              <Tooltip title="Nicht erreichbar und Rückrufmail senden">
                <Button variant="contained" onClick={(e) => { handleRR(e); setModalOpen(true); }} color="inherit" style={{ width: "48%", padding: "2px", margin: "4px" }}><PhoneMissed></PhoneMissed> + <Email></Email></Button>
              </Tooltip>

            </div>

            <div>

            </div>
                
          </form>
        }
        <MissedCallMailDialog open={modalOpen} setOpen={setModalOpen} organizationId={clickedCompany?.id} personId={clickedPerson?.id} phoneCardMode={phoneCardMode}/>
      </div>
    </Card>
  )
}

export default PhoneCard