import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { IService } from '../../Interfaces/Service'
import styles from './Services.module.css'

//Icons
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { ClickAwayListener, Collapse, Grow, Menu, MenuItem, MenuList, Modal, Paper, Popper } from '@mui/material';

import MoreOptionsButton from '../../Components/Buttons/MoreOptionsButton/MoreOptionsButton';
import { IProductDTO, useProducts } from '../../Contexts/ProductContext/ProductProvider';
import { useModals } from '../../Contexts/DialogContext/DialogProvider';
import FormRow from '../../Components/Forms/FormUtils/FormRow';
import CTAButton from '../../Components/Buttons/CTAButton/CTAButton';
import { Delete } from '@mui/icons-material';


interface props {
    product: any
    setProduct: any
    deleteProduct?: any
}

interface Document {
    id: number,
    title: string,
    description: string,
    fileEnding: string,
    fileId: string,
    base64: string,
    organizationId: number,
}

const ProductRow = ({ product, setProduct, deleteProduct }: props) => {

    const productContext = useProducts();
    const dialogContext = useModals();

    const onPressEdit = () => {
        dialogContext.changeVisibility!("editCreateProduct", true, { props: { object: product, mode: "edit" } });
    }

    const onPressDelete = () => {
        productContext.removeProduct(product);
    }

    const options = [
        {
            text: "Bearbeiten",
            function: onPressEdit
        },
        {
            text: "Löschen",
            function: onPressDelete
        }
    ]

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);


    return (
        <>
            {product &&
                <div className={'flex column '.concat(styles.container)} style={{ backgroundColor: "white" ?? "transparent" }}>
                    <header className='flex centered-aligned stretched-justify'>
                        <div className='flex centered-aligned' style={{ gap: "6px", width: "66%" }}>
                            <div className={(product.count !== 0) ? styles.accent : styles.outgoing} />
                            <span className='bold-big'>{product.title ?? ""}</span>
                        </div>
                        <div className='flex' style={{ flexDirection: "row", justifyContent: "end", gap: "0.66em" }}>
                            <div style={{ width: "30%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <span style={{ textOverflow: "ellipsis" }}>{"Menge: "}</span>
                            </div>
                            <div style={{ width: "30%" }}>
                                <FormRow
                                    type='number'
                                    onChange={(val: string) => {
                                        setProduct(val);
                                    }}
                                    value={product.count}
                                    step='1'
                                />
                            </div>
                            <div style={{ width: "30%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <CTAButton onClickHandler={() => deleteProduct()} title={<Delete />} variant='contained' color='error'></CTAButton>
                            </div>
                        </div>
                    </header>
                </div>
            }
        </>
    )
}

export default ProductRow;