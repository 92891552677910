import { useEffect, useState } from "react";
import CardColumn from "../../Components/Card/CardColumn/CardColumn";

import { Add } from '@mui/icons-material';
import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import Card from '../../Components/Card/Card';
import FormRow from '../../Components/Forms/FormUtils/FormRow';
import { InputFieldErrorMessages } from '../../Components/InputField/InputFieldRefactored';
import ServiceFullRow from '../../Components/Service/ServiceFullRow';
import { IPosition, useBills } from '../../Contexts/BillContext/BillProvider';
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider';
import { useServices } from '../../Contexts/ServiceContext/ServiceContext';
import useAuthConnection from '../../Hooks/useAuthConnection';
import { IBillDTO } from '../../Interfaces/Bill';
import { IOrganization } from '../../Interfaces/Company';
import { IService, IServiceDTO } from '../../Interfaces/Service';
import PositionFullRow from "../../Components/Service/PositionFullRow";


const FirmenAbrechnung = () => {
    const [error, setError] = useState<any>({});
    const validateInput = (justCheckForCompletion?: boolean) => {
        if ((bill?.customerId === undefined || bill.customerId <= 0) && !justCheckForCompletion) {
            setError((old: any) => ({ ...old, customerError: true }))
        } else if ((bill?.customerId !== undefined && bill.customerId > 0)) {
            setError((old: any) => ({ ...old, customerError: false }))
        }

        if ((bill?.prefix === undefined || bill.prefix <= 0) && !justCheckForCompletion) {
            setError((old: any) => ({ ...old, billPrefixError: true }))
        } else if ((bill?.prefix !== undefined && bill.prefix > 0)) {
            setError((old: any) => ({ ...old, billPrefixError: false }))
        }

        if (billNumberLoading && !justCheckForCompletion) {
            setError((old: any) => ({ ...old, billNumberLoadingError: true }));
        } else if (!billNumberLoading) {
            setError((old: any) => ({ ...old, billNumberLoadingError: false }));
        }

        if (bill?.billNumber === undefined && !justCheckForCompletion) {
            setError((old: any) => ({ ...old, billNumberEmptyError: true }));
        } else if (bill?.billNumber !== undefined) {
            setError((old: any) => ({ ...old, billNumberEmptyError: false }));
        }

        if (bill?.customerId === undefined || bill.customerId <= 0 || bill?.prefix === undefined || bill.prefix <= 0 || billNumberLoading || bill?.billNumber === undefined) {
            return false;
        }

        return true;
    }



    const { companies } = useContacts();
    const { services, setServicesBilled } = useServices() //Hier werden Leistungen auf verrechnet gesetzt (die Funktion macht das)
    const { addBillWithBillingPositions, billables, billPrefixes } = useBills();

    const [bill, setBill] = useState<IBillDTO>(); //macht d. Rechnung
    const [billNumberLoading, setBillNumberLoading] = useState(false);
    const [copiedServices, setCopiedServices] = useState<IService[]>([]); //holt offene Leistungen
    const [selectedServices, setSelectedServices] = useState<Array<number>>([])
    const [selectedPositions, setSelectedPositions] = useState<Array<number>>([])

    const [billablesForCompany, setBillablesForCompany] = useState<Array<IPosition>>([])

    useEffect(() => {
        setBillablesForCompany(billables.filter((billable: IPosition) => billable.customerOrganizationId === bill?.customerId))
    }, [bill?.customerId, bill?.personId, billables])

    const selectAll = () => {
        if (selectedPositions.length === billablesForCompany.length) {
            setSelectedPositions([])
        } else {
            setSelectedPositions(billablesForCompany.map((billable: IPosition) => billable.tempId!))
        }
    }

    const connection = useAuthConnection();
    const updateBillnumber = (val?: string) => {
        setBillNumberLoading(true);
        setBill((prev: any) => ({ ...prev, billNumber: "" }))
        connection.get(`/bill/NextBillNumberOfPrefix/${val ?? bill?.prefix}`) //so heißt d. Funktion im Backend
            .then((res: AxiosResponse) => {
                setBill((prev: any) => ({ ...prev, billNumber: res.data }));
                setBillNumberLoading(false);
            })
            .catch((error: any) => {
                setBillNumberLoading(false);
            });
    }


    let navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);

    //hier wird auf verrechnet gesetzt und die ausgewählten Services in ein neues REchnungs-Fenster übertragen (Rechnungserstellung)
    const createBill = () => {
        if (!loading && validateInput()) {
            setLoading(true);
            addBillWithBillingPositions!(bill!, selectedServices, billables.filter((billable: IPosition) => selectedPositions.includes(billable.tempId!)))
                .then((result) => {
                    if (setServicesBilled) {
                        setServicesBilled(selectedServices);
                    }
                    setLoading(false);
                    navigate(`/rechnungswesen/rechnungen/bearbeiten/${result.bill.billPrefix}-${result.bill.billNumber}`, { replace: true })
                })
                .catch((error: any) => {
                    setLoading(false);
                })
        }
    }



    useEffect(() => {
        validateInput(true);
    }, [bill])

    useEffect(() => {
        if (billNumberLoading === false) {
            setError((old: any) => ({ ...old, billNumberLoadingError: false }));
        }
    }, [billNumberLoading])

    const [companiesWithOpenPositions, setCompaniesWithOpenPositions] = useState<Array<IOrganization>>([])
    useEffect(() => {
        let tempCompanieIds = new Set((billables.map((billable: IPosition) => billable.customerOrganizationId))) //map iteriert über das Array und gibt uns nur die ID der Companie zurück
        setCompaniesWithOpenPositions(companies.filter((company: IOrganization) => tempCompanieIds.has(company.id!))) //jetzt schauen wir ob die Company im Array ist.
    }, [companies, services])

    //Set initial customer
    const [initialValueSet, setInitialValueSet] = useState(false);
    useEffect(() => {
        if ((bill?.customerId === undefined || bill.customerId === null) && companiesWithOpenPositions.length > 0 && !initialValueSet) {
            setBill((old: IBillDTO | undefined) => ({ ...old, customerId: companiesWithOpenPositions[0].id }));
            setInitialValueSet(true);
        }
    }, [bill?.customerId, companiesWithOpenPositions])

    return (
        <>
            <CardColumn
                height='100%'
                width='100%'
            >
                <Card
                    title='Firmenabrechnung'
                    style={{ width: "100%", height: "100%" }}
                    cta={true}
                    buttonIcon={<Add />}
                    ctaTitle={!loading ? "Rechnung erstellen" : <CircularProgress size={14}></CircularProgress>}
                    ctaFunction={createBill}
                >
                    <FormRow
                        label='Abzurechnender Kunde'
                        value={bill?.customerId}
                        onChange={(val) => setBill((old: IBillDTO | undefined) => ({ ...old, customerId: val }))}
                        type='select'
                        selectOptions={{
                            options: companiesWithOpenPositions,
                            titleKey: "name",
                            valueKey: "id",
                            multiple: false
                        }}
                        error={error?.customerError}
                        errorMessage={InputFieldErrorMessages.CustomerRequired}
                    />
                    <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                        <FormRow
                            label='Rechnungskreis'
                            value={bill?.prefix}
                            onChange={(val) => { setBill((prev: any) => ({ ...prev, prefix: val })); updateBillnumber(val); }}
                            type='select'
                            placeholder='Rechnungskreis auswählen'
                            selectOptions={{
                                options: billPrefixes ?? [],
                                titleKey: "prefix",
                                valueKey: "id",
                                multiple: false
                            }}
                            error={error?.billPrefixError}
                            errorMessage={InputFieldErrorMessages.BillPrefixRequired}
                        />
                        <div className='form-row-label'>
                            <FormRow
                                label='Rechnungsnummer'
                                value={bill?.billNumber ?? ""}
                                readonly={billNumberLoading}
                                onChange={(val) => setBill((prev: any) => ({ ...prev, billNumber: parseInt(val) }))}
                                type='number'
                                placeholder={billNumberLoading ? 'Nächste Rechnungsnummer wird geladen' : 'Rechnungsnummer eingeben'}
                                error={error?.billNumberEmptyError || error?.billNumberLoadingError}
                                errorMessage={error?.billNumberLoadingError ? InputFieldErrorMessages.BillNumberLoadingError : InputFieldErrorMessages.BillNumberRequired}
                            />
                        </div>
                    </div>
                    <div className={'flex column'} style={{ backgroundColor: "transparent", marginTop: "1em" }}>
                        <header className='flex centered-aligned stretched-justify pointer'>
                            <span style={{ width: "20%" }}><FormControlLabel control={<Checkbox color='secondary' checked={selectedPositions?.length === billablesForCompany.length} onChange={() => {
                                selectAll();
                            }} />} label={"Alle auswählen"} />
                            </span>
                        </header>
                    </div>
                    <div className='flex column' style={{ gap: "5px" }}>
                        {
                            billablesForCompany.length > 0 ?
                                billablesForCompany.map((service, index) => {
                                    return <PositionFullRow selectedPositions={selectedPositions} setSelectedPositions={setSelectedPositions} backgroundColor={index % 2 == 1 ? "var(--ultra-light)" : undefined} position={service} key={index} />
                                })
                                :
                                "Keine Leistungen gefunden"
                        }
                    </div>
                </Card>
            </CardColumn>
        </>
    )
};

export default FirmenAbrechnung;
