/*Standard */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { card } from '../../Interfaces/Card'
import IconTooltipButton from '../Buttons/IconTooltipButton/IconTooltipButton'

/*Design*/
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import StopRoundedIcon from '@mui/icons-material/StopRounded'
import PauseRoundedIcon from '@mui/icons-material/PauseRounded'
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import FlexibleTable from './FlexibleTable'
import { Add, AddRounded, Euro, Percent, Redeem, Replay, Search } from '@mui/icons-material'

/*Klassen*/
import Card from '../Card/Card'
import { IProduct, useProducts } from '../../Contexts/ProductContext/ProductProvider'
import ProductRow from './ProductRow'
import Product from '../Product/Product'
import MoreOptionsButton from '../Buttons/MoreOptionsButton/MoreOptionsButton'
import { useNavigate } from 'react-router'
import styles from './BillsCard.module.css'
import { useModals } from '../../Contexts/DialogContext/DialogProvider'
import { IProductDTO } from '../../Contexts/ProductContext/ProductProvider';
import ProductRowKassa, { ProductRowSummaryCard } from '../ProductCards/ProductRowKassa'
import { useBills } from '../../Contexts/BillContext/BillProvider'
import FormRow from '../Forms/FormUtils/FormRow'
import { IBillDTO } from '../../Interfaces/Bill'
import Modal from '../Dialog/Dialog'
import CTAButton from '../Buttons/CTAButton/CTAButton'
import { Button } from '@mui/material'


interface props extends card {
  chosenProducts: Array<IProduct>
  bill?: IBillDTO
  setBill?: Dispatch<SetStateAction<IBillDTO>>
  setChosenProducts: any
  addChosenProducts: any
  decrementChosenProducts: any
}

function SummaryCard({ width, height, order, chosenProducts, setChosenProducts, addChosenProducts, decrementChosenProducts, bill, setBill }: props) {

  const { products, fetchProducts } = useProducts();
  const { registrierkasse, registrierkasseZertifikat, registrierkasseDatenerfassungsprotokoll } = useBills();

  useEffect(() => {
    fetchProducts();
  }, [])

  const [sum, setSum] = useState<{ brutto: number, netto: number }>({ brutto: 0, netto: 0 });

  useEffect(() => {
    let tempSum = { brutto: 0, netto: 0 };

    chosenProducts.forEach((product: IProduct) => {
      tempSum.brutto += (product.count! * product.salesPrice! * ((product.ust! / 100) + 1));
      tempSum.netto += (product.count! * product.salesPrice!);
    });

    setSum(tempSum);
  }, [chosenProducts])

  const [filteredProducts, setFilteredProducts] = useState(products);
  const [searchValue, setSearchValue] = useState("");



  useEffect(() => {
    setFilteredProducts(products.filter((product: IProduct) => product.title?.includes(searchValue)) ?? [])
  }, [products, searchValue])

  const dialogprovider = useModals();

  const [open, setOpen] = useState<boolean>(false);
  const [percent, setPercent] = useState<boolean>(true);

  const [discountObj, setDiscountObj] = useState<IProduct>();

  return (
    <Card
      title='Rechnungserstellung'
      style={{ width, height, order }}
      fullHeightBody={true}
      disableScroll={true}
      cta={false}
      buttonIcon={<AddRounded fontSize='small' />}
      right={() => <div style={{ cursor: "pointer" }} onClick={() => { setChosenProducts([]) }}><Replay /></div>}
    >
      {(!registrierkasse || !registrierkasseZertifikat) &&
        <div>
          <h5>Ihre Kasse ist nicht registiert, bitte verwenden Sie "Rechnungswesen/Rechnungen"</h5>
          <h5>Für nähere Infos melden Sie sich bitte unter registrierkassa@ecockpit.at oder 0463 890 133</h5>
        </div>
      }

      <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", gap: "1%" }}>
        <div style={{ height: "33%", width: "98%", border: "1px solid #1e1e1e50", borderRadius: "15px", padding: "5px", paddingLeft: "10px", paddingRight: "10px" }}>
          <div style={{ width: "100%", height: "75%", display: "flex", flexDirection: "column", gap: "1.5%" }}>
            <div style={{ width: "100%", height: "10%", display: "flex", flexDirection: "row", gap: "1.5%" }}>
              <div style={{ width: "8%", height: "100%", display: "flex", flexDirection: "column" }}>
                <span style={{ width: "100%", textAlign: "right" }}>
                  Anzahl
                </span>
              </div>
              <div style={{ width: "80%", height: "100%", display: "flex", flexDirection: "column" }}>
                <span style={{ width: "100%", textAlign: "left" }}>
                  Beschreibung
                </span>
              </div>
              <div style={{ width: "10%", height: "100%", display: "flex", flexDirection: "column" }}>
                <span style={{ width: "100%", textAlign: "left" }}>
                  Betrag
                </span>
              </div>
            </div>

            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", gap: "1.5%", overflow: "auto" }}>
              <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", gap: "1.5%" }}>
                <div style={{ width: "8%", height: "auto", display: "flex", flexDirection: "column", backgroundColor: "#5e5e5e50", borderRadius: "5px" }}>
                  <div style={{ width: "100%", minHeight: "100%", height: "auto", borderRadius: "5px", textAlign: "right", paddingRight: "5px", display: "flex", flexDirection: "column" }}>
                    {chosenProducts.map((product: IProduct) => {
                      return <span onClick={() => {
                        if (product.id! < 0) {
                          setDiscountObj(product);
                        }
                        setOpen(true);
                      }}>
                        {product.count}x
                      </span>
                    })}
                  </div>
                </div>
                <div style={{ width: "80%", height: "100%", display: "flex", flexDirection: "column", borderRadius: "5px" }}>
                  <div style={{ width: "100%", height: "auto", borderRadius: "5px", textAlign: "left", paddingRight: "5px", display: "flex", flexDirection: "column" }}>
                    {chosenProducts.map((product: IProduct) => {
                      return <span style={{ fontWeight: "600" }}>
                        {product.title}
                      </span>
                    })}
                  </div>
                </div>
                <div style={{ width: "10%", height: "100%", display: "flex", flexDirection: "column", backgroundColor: "#5e5e5e50", borderRadius: "5px" }}>
                  <div style={{ width: "100%", minHeight: "100%", height: "auto", borderRadius: "5px", textAlign: "right", paddingRight: "5px", display: "flex", flexDirection: "column" }}>
                    {chosenProducts.map((product: IProduct) => {
                      return <span>
                        {(product.count! * product.salesPrice! * ((product.ust! / 100) + 1)).toFixed(2)} €
                      </span>
                    })}
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div style={{ width: "100%", height: "13%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <span style={{ fontSize: "x-large", fontWeight: "700" }}>Summe</span>
            <span style={{ fontSize: "x-large", fontWeight: "700" }}>{sum.brutto?.toFixed(2)} €</span>
          </div>
          <div style={{ width: "100%", height: "1.25%", backgroundColor: "black" }} />
          <div style={{ width: "100%", height: "16%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <span>{chosenProducts.length ?? 0} Position(en)</span>
            <span>Netto: {(sum.netto?.toFixed(2))} €</span>
          </div>
        </div>
        <div style={{ height: "65%", width: "98%", display: "flex", flexDirection: "row", gap: "1%" }}>
          <div style={{ height: "99%", width: "66%", border: "1px solid #390099", borderRadius: "15px", overflow: "hidden" }}>
            <div style={{ backgroundColor: "#390099", width: "100%", height: "8%", textAlign: "center", alignItems: "center", justifyContent: "center", display: "flex" }}>
              <span style={{ color: "white", fontSize: "x-large" }}>PRODUKTLISTE</span>
            </div>
            <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", }}>
              <div style={{ width: "100%", height: "4%", textAlign: "center", alignItems: "center", justifyContent: "center", display: "flex", marginTop: "1%", marginBottom: "2%" }}>
                <div style={{ width: "80%", height: "100%", borderRadius: "10px", display: "flex", flexDirection: "column", gap: "1%" }}>
                  <FormRow
                    onChange={(val: string) => setSearchValue(val)}
                    type='text'
                    value={searchValue}
                    placeholder='Suche'
                  />
                </div>
              </div>
              <div style={{ width: "100%", height: "83%", overflow: "auto", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", gap: "5px", marginTop: "10px" }}>
                <div style={{ width: "80%", height: "100%", borderRadius: "10px", display: "flex", flexDirection: "column", gap: "5px", marginTop: "10px" }}>
                  {filteredProducts.map((product: IProduct) => {
                    return (
                      <div onClick={() => { addChosenProducts(product) }} style={{ cursor: "pointer", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "15px", border: "2px solid", borderRadius: "15px", borderColor: chosenProducts.find((temp: IProduct) => temp.id === product.id && (temp.count ?? 0) > 0) ? "var(--brand)" : "#5e5e5e50", backgroundColor: chosenProducts.find((temp: IProduct) => temp.id === product.id && (temp.count ?? 0) > 0) ? "#39009915" : "#5e5e5e15" }}>
                        <span style={{ fontSize: "large", textOverflow: "ellipsis", overflow: "hidden", fontWeight: "500", width: "66%" }}>{product.title}</span>
                        <div style={{ width: "50%", display: "flex", flexDirection: "row", gap: "0.5em" }} onClick={(e) => { e.stopPropagation() }}>
                          <FormRow
                            style={{ width: "50%" }}
                            type='number'
                            onChange={(val: string) => {
                              let x = chosenProducts.find((element: IProduct) => element.id === product.id)

                              if (x) {
                                if (val !== "0") {
                                  setChosenProducts((old: Array<IProduct>) => (old.map((element: IProduct) => {
                                    if (element.id === product.id) {
                                      return { ...element, count: parseInt(val) }
                                    }
                                    return element
                                  })))
                                } else if (val <= "0") {
                                  setChosenProducts((old: Array<IProduct>) => (old.filter((element: IProduct) => element.id !== product.id)))
                                }
                              } else {
                                setChosenProducts((old: Array<IProduct>) => ([...old, { ...product, count: parseInt(val) }]))
                              }
                            }}
                            value={chosenProducts.find((element: IProduct) => element.id === product.id)?.count ?? 0}
                            step='1'
                          />
                          <Button size='small' variant='contained' color='success' onClick={() => { addChosenProducts(product) }}>+</Button>
                          <Button size='small' variant='contained' color='error' onClick={() => { decrementChosenProducts(product) }}>-</Button>
                        </div>
                      </div>
                    )
                  })}
                  <div onClick={() => { dialogprovider.changeVisibility!("editCreateProduct", true, { props: { mode: "create" } }) }} style={{ marginBottom: "100px", cursor: "pointer", display: "flex", padding: "15px", border: "2px solid", borderRadius: "15px", borderColor: "#5e5e5e50", backgroundColor: "#5e5e5e15" }}>
                    <span style={{ fontSize: "large", textOverflow: "ellipsis", overflow: "hidden", fontWeight: "500", display: "flex", alignItems: "center" }}><Add fontSize='inherit' /> Neues Produkt</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: "100%", width: "33%", padding: "5px", paddingLeft: "10px", paddingRight: "10px" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px", height: "26%" }}>
              <div onClick={() => {
                setPercent(true);
                setOpen(true);
              }} style={{ cursor: "pointer", display: "flex", padding: "15px", border: "2px solid", borderRadius: "15px", borderColor: "#5e5e5e50", backgroundColor: "#5e5e5e15" }}>
                <span style={{ fontSize: "large", textOverflow: "ellipsis", overflow: "hidden", fontWeight: "500", display: "flex", alignItems: "center" }}><Percent fontSize='inherit' style={{ marginRight: "0.5em" }} /> Rabatt</span>
              </div>
              <div onClick={() => {
                setPercent(false);
                setOpen(true);
              }} style={{ cursor: "pointer", display: "flex", padding: "15px", border: "2px solid", borderRadius: "15px", borderColor: "#5e5e5e50", backgroundColor: "#5e5e5e15" }}>
                <span style={{ fontSize: "large", textOverflow: "ellipsis", overflow: "hidden", fontWeight: "500", display: "flex", alignItems: "center" }}><Euro fontSize='inherit' style={{ marginRight: "0.5em" }} /> Rabatt</span>
              </div>
              {/* ToDo: implement Gutscheine
              <div onClick={() => {  }} style={{ cursor: "pointer", display: "flex", padding: "15px", border: "2px solid", borderRadius: "15px", borderColor: "var(--brand-light)", backgroundColor: "var(--brand-light-light)" }}>
                <span style={{ fontSize: "large", textOverflow: "ellipsis", overflow: "hidden", fontWeight: "500", display: "flex", alignItems: "center" }}><Add fontSize='inherit' style={{ marginRight: "0.5em" }} /> Gutschein erstellen</span>
              </div>
              <div onClick={() => {  }} style={{ cursor: "pointer", display: "flex", padding: "15px", border: "2px solid", borderRadius: "15px", borderColor: "var(--brand-light)", backgroundColor: "var(--brand-light-light)" }}>
                <span style={{ fontSize: "large", textOverflow: "ellipsis", overflow: "hidden", fontWeight: "500", display: "flex", alignItems: "center" }}><Redeem fontSize='inherit' style={{ marginRight: "0.5em" }} /> Gutschein einlösen</span>
              </div>
              */}
            </div>
            <div style={{ height: "74%", border: "1px solid #5e5e5e50", borderRadius: "15px", width: "100%", overflow: "hidden" }}>
              <FormRow
                onChange={(val: string) => setBill!((old: IBillDTO) => ({ ...old, note: val }))}
                type='multiline'
                value={bill?.note}
                placeholder='Notiz hinzufügen'
                style={{ height: "100%", display: "flex", alignItems: "start", width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
      <RabattModal open={open} setOpen={setOpen} percent={percent} chosenProducts={chosenProducts} setChosenProducts={setChosenProducts} discountObj={discountObj} />
    </Card >
  )
}


interface modalProps {
  open?: boolean
  percent?: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  chosenProducts: Array<IProduct>
  setChosenProducts: Dispatch<SetStateAction<Array<IProduct>>>
  discountObj?: IProduct
}

const RabattModal = ({ open = false, percent = true, setOpen, chosenProducts, setChosenProducts, discountObj }: modalProps) => {

  const [focusedProduct, setFocusedProduct] = useState<IProduct>({})
  const [discount, setDiscount] = useState<number>(0)

  return (
    <Modal
      open={open}
      title='Rabatt'
      onClose={() => { setOpen(false) }}
    >
      {chosenProducts.length > 0 ?
        <>
          <div style={{ marginBottom: "1em" }}>
            {chosenProducts.map((product: IProduct) => {

              if (product.id! < 0) {
                return null
              }

              return (
                <div onClick={() => { setFocusedProduct(product) }} style={{ cursor: "pointer", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "15px", border: "2px solid", borderRadius: "15px", borderColor: focusedProduct.id === product.id ? "var(--brand)" : "#5e5e5e50", backgroundColor: focusedProduct.id === product.id ? "#39009915" : "#5e5e5e15" }}>
                  <span style={{ fontSize: "large", textOverflow: "ellipsis", overflow: "hidden", fontWeight: "500", width: "75%" }}>{product.title}</span>
                  <div style={{ width: "25%", cursor: "pointer" }}>
                    <FormRow
                      type='number'
                      onChange={(val: string) => { }}
                      readonly
                      value={chosenProducts.find((element: IProduct) => element.id === product.id)?.count ?? 0}
                      step='1'
                    />
                  </div>
                </div>
              )
            })}
          </div>
          <div style={{ marginBottom: "1em" }}>
            <FormRow
              label={'Rabatt in ' + (percent ? '%' : '€')}
              type='number'
              step='1'
              onChange={(val: string) => { setDiscount(parseInt(val)) }}
              value={discount}
            />
          </div>
          <CTAButton
            title="Rabatt erstellen"
            onClickHandler={() => { setChosenProducts((old) => ([...old, { ...focusedProduct, title: "Rabatt zu " + focusedProduct.title, id: percent ? -1 : -2, salesPrice: percent ? - (focusedProduct.salesPrice! * (discount / 100)) : -(discount / 1.2) }])); setOpen(false); }}
          />
        </>
        :
        <>
          <div>
            <span>Sie müssen zuerst ein Produkt hinzufügen</span>
          </div>
        </>
      }
    </Modal>
  )
}

export default SummaryCard