import { CircularProgress } from '@mui/material'
import { useState } from 'react'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import FormRow from "../../Forms/FormUtils/FormRow";
import { useStaff } from '../../../Contexts/StaffContext/StaffProvider'
import { useContacts } from '../../../Contexts/ContactsContext/ContactsProvider'
import Android12Switch from '../../Buttons/Switch/Android12Switch'


interface props {
  open?: boolean,
  onClose?: any,
  filter?: any,
  setFilter?: any,
  submitChange?: any
  setOpen?: any
}

function TaskFilterDialog({ open = false, setOpen, onClose, filter, setFilter, submitChange }: props) {
  //*Ladestate beim Abschicken an die API
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    try {
      setLoading(true)
      if (submitChange) {
        submitChange()
      }
      if (handleClose) {
        handleClose()
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }

  }

  const handleClose = async () => {
    setLoading(false)
    if (setFilter) {
      setFilter((old: any) => ({ ...old, open: false }))
    }
    if (onClose) {
      onClose()
    }
  }

  const { staff } = useStaff();
  const { companies } = useContacts();

  return (
    <Modal
      open={open}
      title={"Filter Aufgaben"}
      onClose={() => setOpen(false)}
    >
      <FormRow
        label='Mitarbeiter'
        onChange={(val) => setFilter((old: any) => ({ ...old, userIds: val }))}
        type='select'
        value={filter?.userIds}
        selectOptions={{
          options: staff,
          titleKey: 'username',
          valueKey: 'id',
          multiple: true
        }}
      />
      <FormRow
        label='Kunden'
        onChange={(val) => setFilter((old: any) => ({ ...old, companyIds: val }))}
        type='select'
        value={filter?.companyIds}
        selectOptions={{
          options: companies,
          titleKey: 'name',
          valueKey: 'id',
          multiple: true
        }}
      />
      <div className='form-row' style={{ marginTop: "0.5em" }}>
        <span>
          Abgeschlossen
        </span>
        <Android12Switch color='secondary' value={filter?.finished} onChange={() => setFilter((old: any) => ({ ...old, finished: !old.finished }))} />
      </div>
      <CTAButton
        style={{
          marginTop: "20px"
        }}
        title={loading ? <CircularProgress size={18} color='inherit' /> : "Übernehmen"}
        onClickHandler={() => handleSubmit()}
        disabled={loading}
      />
    </Modal>
  )
}

export default TaskFilterDialog