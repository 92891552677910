import { Collapse } from "@mui/material";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBills } from "../../../Contexts/BillContext/BillProvider";
import { useModals } from "../../../Contexts/DialogContext/DialogProvider";
import { IPause, IWorkTime, useTimeTracking } from "../../../Contexts/TimeTrackingContext/TimeTrackingProvider";
import { useUser } from "../../../Contexts/UserContext/UserContext";
import useAuthConnection from "../../../Hooks/useAuthConnection";
import { IBillListDTO } from "../../../Interfaces/Bill";
import MoreOptionsButton from "../../Buttons/MoreOptionsButton/MoreOptionsButton";
import styles from './BillsCard.module.css'
import dayjs from "dayjs";



interface props {
    workTime?: IWorkTime,
    pauses?: IPause[],
    n?: number
}

interface Document {
    id: number,
    title: string,
    description: string,
    fileEnding: string,
    fileId: string,
    base64: string,
    organizationId: number,
}

const PauseRow = ({ workTime, pauses, n }: props) => {
    const [expanded, setExpanded] = useState(false);

    const { user } = useUser();
    const dialogContext = useModals();

    const worktimeContext = useTimeTracking();

    const onPressEdit = () => {
        dialogContext.changeVisibility!("worktimeOverview", false);
        dialogContext.changeVisibility!("postscriptWorkPause", true, { props: { object: workTime, mode: "editpause" } });
        /*if (billDTO.bill.isReceipt === false) {
            navigate(`bearbeiten/${bill.billPrefix}-${bill.billNumber}`);
        } else {
            navigate(`/rechnungswesen/belege/bearbeiten/${bill.billPrefix}-${bill.billNumber}`, { replace: true })
        }*/
    }

    const onPressDelete = () => {
        //TODO: YesNo-Modal
        /*billContext.deleteBill!(bill);
        connection.delete("/bill", { data: bill })
            .then((res: AxiosResponse) => {
            });*/
        if (workTime) {
            //worktimeContext.deleteWorktime!(workTime);
            worktimeContext.deletePause!({
                id: workTime.id,
                organizationId: user?.organizationId!,
                userId: user?.userId!,
                start: workTime.start ?? dayjs()
            })
        }
    }

    const options = [
        {
            text: "Bearbeiten",
            function: onPressEdit
        },
        {
            text: "Löschen",
            function: onPressDelete
        }
    ]

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

   
    const end = dayjs(workTime?.end);
    const start = dayjs(workTime?.start);

    let timeDifferenceMin = end.diff(start, 'minute');
    let timeDifferenceHours = timeDifferenceMin / 60;

    let totalPauseDurationMin = 0;
    let timeDifferencePauseHours = 0;

    const [timeDifferencePauseMin, setTimeDifferencePauseMin] = useState<number>(0);

    useEffect(() => {
        totalPauseDurationMin = 0;

        const now = dayjs();

        if (pauses) {
            pauses.forEach((element: IPause) => {

                const pauseStart = element.start ?? now;
                const pauseEnd = element.end ?? now;

                const pauseDuration = end.diff(start, 'minute');

                totalPauseDurationMin += pauseDuration
            });
            timeDifferencePauseHours = totalPauseDurationMin / 60;
            setTimeDifferencePauseMin(totalPauseDurationMin);
        }
    }, [pauses])

    // return focus to the button when we transitioned from !open -> open
    /*const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);*/


    return (
        <div style={{ backgroundColor: n ? (n % 2 == 0 ? "white" : "#e9e9e9") : "white", width: "100%" }}>
            <div className={'flex column '.concat(styles.container)} style={{}}>
                <header className='flex centered-aligned'>
                    <div style={{ minWidth: "85%", display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
                        <div style={{ minWidth: "40%", display: "flex", justifyContent: "center" }}>
                            <span className='bold-big' style={{ whiteSpace: "nowrap" }}>{new Date(workTime?.start?.toString() + "" ?? "").toLocaleDateString("de-DE", { weekday: "short", day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" })}</span>
                        </div>
                        <div style={{ width: "20%", display: "flex", flexDirection: "row", justifyContent: "center", }}></div>
                        <div style={{ minWidth: "40%", display: "flex", justifyContent: "center" }}>
                            <span className='bold-big' style={{}}>{workTime?.end ? new Date(workTime?.end?.toString() + "" ?? "").toLocaleDateString("de-DE", { weekday: "short", day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" }) : "Active"}</span>
                        </div>
                    </div>
                    <div style={{ width: "10%" }}>
                        <MoreOptionsButton options={options}></MoreOptionsButton>
                    </div>
                </header>
                <section className='label' style={{ gap: "10px", marginTop: "5px", width: "85%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ width: "40%", display: "flex", flexDirection: "row", justifyContent: "center", }}>
                        <span style={{ textOverflow: "ellipsis" }}>
                        </span>
                    </div>
                    <div style={{ width: "20%", display: "flex", flexDirection: "row", justifyContent: "center", }}>
                        <span style={{ textOverflow: "ellipsis" }}>
                            Pause
                        </span>
                    </div>
                    <div style={{ width: "40%", display: "flex", flexDirection: "row", justifyContent: "center", }}>
                        <span style={{ textOverflow: "ellipsis" }}>
                            
                        </span>
                    </div>
                </section>
                <section className='label' style={{ gap: "10px", marginTop: "5px", width: "85%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ width: "40%", display: "flex", flexDirection: "row", justifyContent: "center", }}>
                        <span style={{ textOverflow: "ellipsis" }}>
                        </span>
                    </div>
                    <div style={{ width: "20%", display: "flex", flexDirection: "row", justifyContent: "center", }}>
                        <span style={{ textOverflow: "ellipsis" }}>
                            {timeDifferenceMin < 60.01 ? timeDifferenceMin.toFixed(0) + " min" : (timeDifferenceHours.toFixed(0)) + " h " + (timeDifferenceMin % 60).toFixed(0) + " min"}
                        </span>
                    </div>
                    <div style={{ width: "40%", display: "flex", flexDirection: "row", justifyContent: "center", }}>
                        <span style={{ textOverflow: "ellipsis" }}>
                        </span>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default PauseRow;