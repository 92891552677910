import { useEffect, useState } from "react";
import { IProjectDTO, defaultProjectDTO, useTasks } from "../../../Contexts/TaskContext/TaskContext";
import CTAButton from "../../Buttons/CTAButton/CTAButton";
import Modal from "../../Dialog/Dialog";
import CreateEditProjectForm from "../../Forms/Projects/CreateEditProjectForm";
import { dialogWithProps } from "../EditCreateCompanyDialog/EditCreateCompanyDialog";

const EditCreateProjectModal = ({ open, onClose, mode, object }: dialogWithProps<IProjectDTO>) => {

    const [project, setProject] = useState<IProjectDTO>(object ?? defaultProjectDTO);

    const { addProject, updateProject } = useTasks();

    const handleSubmit = () => {
        if (mode === "edit" && updateProject) {
            updateProject(project);
            if (onClose) onClose();
        }
        else {
            addProject!(project);
            if (onClose) onClose();
        }
    };

    useEffect(() => {
        if (object) setProject(object);
    }, [object])

    return (
        <Modal
            title={mode === "edit" ? "Projekt bearbeiten" : "Projekt erstellen"}
            open={open}
            onClose={onClose}
        >
            <CreateEditProjectForm object={project} setObject={setProject} />

            <CTAButton
                title='Speichern'
                onClickHandler={() => handleSubmit()}
            />
        </Modal>
    )
}

export default EditCreateProjectModal;