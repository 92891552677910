import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { IEmployee, useEmployees } from '../../Contexts/EmployeeContext/EmployeeProvider'
import { useTasks } from '../../Contexts/TaskContext/TaskContext'
import { useUser } from '../../Contexts/UserContext/UserContext'
import { ITaskDTO } from '../../Interfaces/Task'
import { taskFormModes } from '../../Pages/Aufgaben/Aufgaben'
import ResetFormButton from '../Buttons/ResetFormButton/ResetFormButton'
import InputField from '../InputField/InputField'
import { InputFieldErrorMessages } from '../InputField/InputFieldRefactored'
import FormRow from './FormUtils/FormRow'
import { useServices } from '../../Contexts/ServiceContext/ServiceContext'
import { Delete } from '@mui/icons-material'
import { Button } from '@mui/material'
import { IDocument } from '../../Contexts/DocumentContext/DocumentContext'
import CTAButton from '../Buttons/CTAButton/CTAButton'
import ProductRow from '../../Pages/Services/ProductRow'
import { IProduct, useProducts, IProductDTO } from '../../Contexts/ProductContext/ProductProvider'
import dayjs from 'dayjs'

export interface form<T, M = void> {
    object: T
    setObject: React.Dispatch<React.SetStateAction<T>>
    mode?: string
    setFormMode?: Dispatch<SetStateAction<M>>
    submitFunction?: () => void
    loading?: boolean
    setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
    /*
        used to pass error variables to a form
        required when the submit method for the form is in a different parent component
    */
    errorVariables?: Record<string, boolean>
}

const employees = [
    {
        name: "Fabian",
        id: 1
    },
    {
        name: "Nico",
        id: 2
    }
]


const CreateTaskForm = ({ object, setObject, mode = "createTask", setFormMode, errorVariables }: form<ITaskDTO, taskFormModes>) => {

    const { companies } = useContacts()

    const { employees } = useEmployees()

    const { user } = useUser();

    const { defaultTask, getPicturesToTask, deleteDocumentToTask, projects } = useTasks();

    const { taskServiceCategories } = useServices();

    const { products } = useProducts();

    const handleReset = () => {

        setObject(defaultTask!);
        setFormMode!("createTask");
    }

    //Get all employees who are active
    const [activeEmployees, setActiveEmployees] = useState(employees.filter((employee: IEmployee) => employee.active))

    useEffect(() => {
        setActiveEmployees(employees.filter((employee: IEmployee) => employee.active))
    }, [employees])

    useEffect(() => {
        if (getPicturesToTask && object.id) {
            getPicturesToTask(object.id).then((res) => {
                setDocuments(res)
                console.log(res)
            });
        }
    }, [object])

    const [documents, setDocuments] = useState<Array<IDocument>>([])

    const [product, setProduct] = useState<IProduct>();

    const [newProduct, setNewProduct] = useState();


    return (
        <form className='form'>
            <div className='form-row-label'>
                <span className="label">
                    Autor:
                </span>
                <InputField
                    placeholder='Autor'
                    value={employees?.find((employee: IEmployee) => employee.id === object?.userId)?.username ?? employees?.find((employee: IEmployee) => employee?.id === user?.userId)?.username}
                    isReadOnly={true}
                    onChangeHandler={() => { }}
                />
            </div>
            <FormRow
                type='select'
                label="Mitarbeiter*innen"
                placeholder='Mitarbeiter*innen auswählen'
                value={object?.userIds}
                onChange={(val) => setObject((prev) => ({ ...prev, userIds: val }))}
                selectOptions={{
                    //Legt den Titel-Key des aktiven Objekts fest -> Wert wird in das Feld eingefügt.
                    titleKey: "username",
                    //Legt den Wert-Key des aktiven Objekts fest -> Wert wird demnach an den onChange-Handler übergeben.
                    valueKey: "id",
                    options: activeEmployees,
                    multiple: true
                }}
                error={errorVariables?.employeeError}
                errorMessage={InputFieldErrorMessages.EmployeeRequired}
            />
            {/*<FormRow
                value={object.intern}
                type="Switch"
                onChange={(val) => setObject((old) => ({ ...old, intern: val }))}
                title="Intern"
            />*/}
            <FormRow
                type='select'
                label="Kunde"
                placeholder='Kunde auswählen'
                value={object?.customerOrganizationId}
                onChange={(val: number) => {
                    setObject((prev) => ({ ...prev, customerOrganizationId: val }))
                }
                }
                selectOptions={{
                    //Legt den Titel-Key des aktiven Objekts fest -> Wert wird in das Feld eingefügt.
                    titleKey: "name",
                    //Legt den Wert-Key des aktiven Objekts fest -> Wert wird demnach an den onChange-Handler übergeben.
                    valueKey: "id",
                    options: companies
                }}
                error={errorVariables?.customerError}
                errorMessage={InputFieldErrorMessages.CustomerRequired}
            />
            {mode === "createTask" &&
                <FormRow
                    type='select'
                    label="Produkt als Vorlage (optional)"
                    placeholder='Produkt auswählen'
                    value={product?.id}
                    onChange={(val: number) => {
                        let tempProduct = products.find(p => p.id === val)
                        setProduct(tempProduct)
                        setObject((prev) => ({ ...prev, title: tempProduct?.title, description: tempProduct?.description }))
                    }
                    }
                    selectOptions={{
                        //Legt den Titel-Key des aktiven Objekts fest -> Wert wird in das Feld eingefügt.
                        titleKey: "title",
                        multiple: false,
                        //Legt den Wert-Key des aktiven Objekts fest -> Wert wird demnach an den onChange-Handler übergeben.
                        valueKey: "id",
                        options: products
                    }}
                //error={errorVariables?.customerError}
                //errorMessage={InputFieldErrorMessages.CustomerRequired}
                />
            }
            <FormRow
                type='text'
                value={object?.title}
                onChange={(val) => setObject((prev) => ({ ...prev, title: val }))}
                label="Bezeichnung (Rechungsposition)"
                placeholder='Bezeichnung'
                maxLength={500}
                error={errorVariables?.titleError}
                errorMessage={InputFieldErrorMessages.TitleError}
            />
            <FormRow
                type='multiline'
                value={object?.description ?? ""}
                onChange={(val) => setObject((prev) => ({ ...prev, description: val }))}
                label='Beschreibung'
                placeholder='Beschreibung (max. 1500 Zeichen)'
                maxLength={1500}
            />
            <div className='form-row-label' style={{ marginBottom: "0em" }}>
                <span className='label'>Produkte</span>
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-start", marginBottom: "1em", gap: "10px" }}>
                <FormRow
                    label=''
                    value={newProduct}
                    onChange={(val) => { setNewProduct(val) }}
                    type='select'
                    selectOptions={{
                        options: products,
                        titleKey: "title",
                        valueKey: "id",
                        multiple: false
                    }}
                />
                <CTAButton
                    style={{ width: "50%" }}
                    onClickHandler={() => {

                        setObject((old: any) => {
                            let productAlreadyThere = old.products?.find((product: any) => product.productId === newProduct);
                            if (productAlreadyThere !== undefined) {
                                return {
                                    ...old, products: old.products!.map((tempProduct: any) => {
                                        if (productAlreadyThere!.productId === tempProduct.productId) {
                                            return { ...tempProduct, count: parseInt(tempProduct.count! + "") + 1 }
                                        }
                                        return tempProduct
                                    })
                                }
                            }

                            let tempProduct = products.find(product => product.id === newProduct);
                            return { ...old, products: [...old.products ?? [], { ...tempProduct, productId: tempProduct?.id, id: tempProduct?.id, count: 1, netto: tempProduct?.salesPrice } ?? {}] }
                        })
                    }}
                    title={"Produkt hinzufügen"}
                />
            </div>
            <div className='form-row-label' style={{ marginBottom: "1em" }}>
                {object.products?.map((product: IProductDTO) => {
                    return <>
                        <ProductRow product={product} setProduct={(value: number) => {
                            setObject((old: any) => ({
                                ...old, products: old.products?.map((tempProduct: any) => {
                                    if (product.id == tempProduct.id) {
                                        return { ...tempProduct, count: value }
                                    }
                                    return tempProduct
                                })
                            }))
                        }} deleteProduct={() => { setObject((old: any) => ({ ...old, products: old.products?.filter((tempProduct: IProductDTO) => tempProduct.id !== product.id) })) }} />
                    </>
                })}
            </div>
            <FormRow
                type='select'
                label="Kategorie (optional)"
                value={object.categoryId}
                onChange={(val) => setObject((old) => ({ ...old, categoryId: val }))}
                selectOptions={{
                    options: taskServiceCategories,
                    titleKey: "name",
                    valueKey: "id"
                }}
            />
            <FormRow
                value={object?.projectId}
                type="select"
                placeholder='Projekt'
                onChange={(val) => setObject((old) => ({ ...old, projectId: val }))}
                label="Projekt zuordnen (optional)"
                selectOptions={{
                    options: projects,
                    titleKey: "title",
                    valueKey: "id"
                }}
            />
            <FormRow
                value={object?.scheduledDate}
                type="date"
                onChange={(val) => setObject((old) => ({ ...old, scheduledDate: val }))}
                label="Geplante Fertigstellung (optional)"
            />
            <FormRow
                value={mode === "editTask" ? object?.creationDate : object?.creationDate ?? dayjs()}
                type='date'
                dateOptions={{
                    type: "Date"
                }}
                onChange={() => { }}
                readonly
                label='Erstellungsdatum'
            />
            <FormRow
                type='multiline'
                value={object?.note ?? ""}
                onChange={(val) => setObject((old) => ({ ...old, note: val }))}
                label="Notiz (optional)"
                placeholder='Notiz (max. 1500 Zeichen'
                maxLength={1500}
            />
            <ResetFormButton
                title="Felder zurücksetzen"
                onClickHandler={() => handleReset()}
            />
            {documents && documents?.length > 0 &&
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h2 style={{ width: "100%" }}>Aufgenommene Bilder:</h2>
                    {documents?.map((doc: IDocument, index: number) => (
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: index === (documents?.length ?? 0) - 1 ? "0em" : "1em" }}>
                            <img style={{ width: "calc(85%)", marginBottom: "0.25em" }} key={index} src={`data:${doc.fileEnding};base64,${doc.base64}`} alt={`captured-${index}`} />
                            {!(user?.organizationRoleId === 20147 || user?.organizationRoleId === 20165) && //EHParkett & MasterCares
                                <Button startIcon={<Delete />} onClick={() => { console.log(doc); if (deleteDocumentToTask) { setDocuments((old: Array<IDocument>) => (old.filter((tempDoc: IDocument) => doc.id !== tempDoc.id))); deleteDocumentToTask(object.id!, doc.id!) } }} color="error" variant='contained'>Entfernen</Button>
                            }
                        </div>
                    ))}
                </div>
            }
        </form>
    )
}

export default CreateTaskForm